import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Callback() {
    const navigate = useNavigate();
    navigate("/control");

    // useEffect(() => {
    //     if (window.location.pathname === "/") {
    //       navigate("/control");
    //     }
    //   }, [window.location.pathname]);
    
    return <div>Callback</div>
};
export default Callback;