import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./App.scss";
import Navbar from "./components/Navbar";
import { useEffect } from "react";
import { Auth0Provider } from "@auth0/auth0-react";

//https://auth0.com/docs/libraries/auth0-single-page-app-sdk#get-access-token-with-no-interaction

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  // console.debug(process.env);

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || redirectUri);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/control");
    }
  }, [location.pathname]);

  if (!(domain && clientId && redirectUri)) {
    return null;
  } else {
    return (
      <div>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          authorizationParams={{
            redirect_uri: redirectUri,
            audience: audience
          }}
          onRedirectCallback={onRedirectCallback}
          cacheLocation="localstorage"
          useRefreshTokens={true}
          useRefreshTokensFallback={true}
        >
          {/* <Navbar /> */}
          <Outlet />
        </Auth0Provider>
      </div>
    );
  }
}

export default App;
