import "../App.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef, useState } from "react";
// import sledSvg from "../assets/sled.svg";
// import wheelSled from "../assets/wheel-sled.png";
// import trackSled from "../assets/track-sled.svg";
import { ControlParams, Mission, Sled, SledGroup, SledStatus, Waypoint } from "../C2Client";

import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import * as turf from "@turf/turf";
import { uniqueId } from "lodash";
import moment from "moment";
import { Position } from "@turf/turf";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;

const defaultCoordinates = {
  lng: -83.698,
  lat: 42.299995,
  zoom: 17,
};

function MissionControlMap({
  dataLoaded,
  sleds,
  missions,
  activeSledGroup,
  activeSled,
  activeMission,
  showPreview,
  actualPath,
  cParams
}: {
  dataLoaded: boolean;
  sleds: Sled[];
  missions: Mission[];
  activeSledGroup?: SledGroup | null;
  activeSled?: Sled | null;
  activeMission?: Mission | null;
  showPreview: boolean;
  actualPath: Position[] | null;
  cParams: ControlParams | null;
}) {
  const mapContainer = useRef<any | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [lng, setLng] = useState(defaultCoordinates.lng);
  const [lat, setLat] = useState(defaultCoordinates.lat);
  const [zoom, setZoom] = useState(defaultCoordinates.zoom);
  const [moveHandled, setMoveHandled] = useState<boolean>(false);
  const [hasStyleData, setHasStyleData] = useState<boolean>(false);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      center: [lng, lat],
      fadeDuration: 0,
      zoom: zoom,
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    function handleStyleData() {
      setHasStyleData(true);
    }

    map.current.on("styledata", handleStyleData);

    return () => {
      if (!map.current) return;
      map.current.off("styledata", handleStyleData);
    };
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.on("load", () => {
      if (!map.current) return;
      console.debug("map loaded");

      // Load an image from an external URL.
      if (!map.current.hasImage("WP_Green")) {
        // console.debug("map does not have image. loading...");
        map.current.loadImage(
          `${window.location.origin}/WP_Green.png`,
          (error: any, image: any) => {
            if (error) console.error(error);
            if (!map.current) return;
            // Add the image to the map style.
            if (!map.current.hasImage("WP_Green")) {
              // console.debug("map still does not have image. adding...");
              map.current.addImage("WP_Green", image);
            }
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    if (!map.current || moveHandled) return; // wait for map to initialize

    function handleMapMove() {
      if (!map.current) return;

      setLng(map.current.getCenter().lng);
      setLat(map.current.getCenter().lat);
      setZoom(map.current.getZoom());
      setMoveHandled(true);
    }

    map.current.on("move", handleMapMove);

    return () => {
      if (!map.current) return;
      map.current.off("move", handleMapMove);
      setMoveHandled(false);
    };
  });

  useEffect(() => {
    if (!map.current || !activeSledGroup || !cParams) return; // wait for map to initialize

    if (cParams?.sledID === activeSled?.sledID) {
      var points = turf.points([
        [activeSled.lastLongitude, activeSled.lastLatitude],
      ]);
      var center = turf.center(points);

      const c = map.current.getCenter();
      const from = turf.point([c.lng, c.lat] as Position);
      const to = turf.point([activeSled.lastLongitude, activeSled.lastLatitude] as Position);
      var options = { units: "kilometers" as turf.Units };
      const dist = turf.distance(from, to, options);

      // map.current.flyTo({
      //   center: center.geometry.coordinates as mapboxgl.LngLatLike,
      //   zoom: 17,
      //   duration: dist < 10 ? 1000 : 2000,
      //   essential: true,
      //   // animate: dist < 100
      //   animate: false
      // });

      map.current.panTo(center.geometry.coordinates as mapboxgl.LngLatLike, {
        duration: dist < 100 ? 1000 : 2000,
        essential: true,
        animate: dist < 100
      });

      return;
    }

    if (activeSledGroup) {
      var points = turf.points([
        [activeSledGroup.defaultLongitude, activeSledGroup.defaultLatitude],
      ]);
      var center = turf.center(points);
      
      map.current.flyTo({
        center: center.geometry.coordinates as mapboxgl.LngLatLike,
        zoom: 17,
        duration: 1000,
        essential: true,
        animate: false
      });
    }
  }, [activeSledGroup, cParams?.sledID]);

  useEffect(() => {
    if (!map.current || !dataLoaded || !hasStyleData) return; // wait for map to initialize

    const activeSleds = sleds.filter(
      (s) => s.sledGroupID === activeSledGroup?.sledGroupID
    );
    const activeMissionNames = activeSleds.map((s) => s.lastMission);

    missions.map((m, i) => {
      if (!map.current) return;

      // var points: SledStatus[] = [];

      const features = m?.waypoints.map((wp, i) => {
        // if (m.missionID === activeMission?.missionID) {
        //   if (i > 0 && i < m.waypoints.length) {
        //     var linePoint = turf.lineString([
        //       [m.waypoints[i - 1].longitude, m.waypoints[i - 1].latitude],
        //       [m.waypoints[i].longitude, m.waypoints[i].latitude],
        //     ]);
        //     var options = { units: "kilometers" as turf.Units };

        //     var from = turf.point([
        //       m.waypoints[i - 1].longitude,
        //       m.waypoints[i - 1].latitude,
        //     ]);
        //     var to = turf.point([
        //       m.waypoints[i].longitude,
        //       m.waypoints[i].latitude,
        //     ]);

        //     var dKilometers = turf.distance(from, to, options);
        //     var dMeters = dKilometers * 1000;
        //     var bearing = turf.bearing(from, to);

        //     for (var p = 1; p <= Math.floor(dMeters); p++) {
        //       // var along = turf.along(linePoint, 0.001, options);
        //       var along = turf.along(linePoint, p / 1000, options);
        //       var coords = along.geometry.coordinates;

        //       var speed = p < 4 ? Math.floor(wp.speedMS * 0.5) : wp.speedMS;

        //       var status: SledStatus = {
        //         statusID: activeSled!.sledID,
        //         sledID: activeSled!.sledID,
        //         statusMessage: "Running",
        //         statusDate: moment().add(10, "second").toDate(),
        //         mission: m.missionName,
        //         latitude: coords[1],
        //         longitude: coords[0],
        //         heading: bearing,
        //         latitudeAccuracy: 0.15,
        //         longitudeAccuracy: 0.15,
        //         speedMS: speed,
        //         batteryStatus: 80,
        //       };

        //       points.push(status);
        //     }

        //     // console.log(`points from ${m.waypoints[i - 1].waypointNumber} to ${m.waypoints[i].waypointNumber}`, { linePoint, points, dMeters, dKilometers });
        //   }
        // }

        const f = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [wp.longitude, wp.latitude],
          },
          properties: {
            missionID: m.missionID,
            waypointID: wp.waypointID,
            waypointNumber: wp.waypointNumber,
          },
        };
        return f;
      });

      // console.log(`${points.length} points along ${m.missionName}'s path`, { points });
      // console.log(JSON.stringify(points));
      // console.log(`point count: ${points.length}`)

      const missionSource = map.current.getSource(`route-${m.missionID}`);

      if (!missionSource) {
        map.current.addSource(`route-${m.missionID}`, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: m.waypoints.reduce((p: any[], c: Waypoint) => {
                p.push([c.longitude, c.latitude]);
                return p;
              }, []),
            },
          },
        });
      }

      const missionRoute = map.current.getLayer(`route-${m.missionID}`);

      if (!missionRoute) {
        map.current.addLayer({
          id: `route-${m.missionID}`,
          type: "line",
          source: `route-${m.missionID}`,
          layout: {
            "line-join": "round",
            "line-cap": "round",
            visibility:
              activeMissionNames.includes(m.missionName) ||
              (activeMission && showPreview)
                ? "visible"
                : "none",
          },
          paint: {
            "line-color":
              activeMission?.missionName === m.missionName ? "#34b44a" : "#fff",
            "line-width": 8,
            "line-opacity": 0.5,
          },
        });
      } else {
        if (activeMission?.missionName === m.missionName) {
          map.current.setPaintProperty(
            missionRoute.id,
            "line-color",
            "#34b44a"
          );
        } else {
          map.current.setPaintProperty(missionRoute.id, "line-color", "#fff");
        }

        map.current.setLayoutProperty(
          missionRoute.id,
          "visibility",
          activeMissionNames.includes(m.missionName) ||
            (activeMission?.missionName === m.missionName && showPreview)
            ? "visible"
            : "none"
        );
      }

      const wpSource = map.current.getSource(`waypoints-${m.missionID}`);
      // Add a data source containing one point feature.
      if (!wpSource) {
        console.debug(`map does not have source. adding waypoints...`);
        map.current.addSource(`waypoints-${m.missionID}`, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: features as any,
          },
        });
      } else {
        (
          map.current.getSource(`waypoints-${m.missionID}`) as GeoJSONSource
        ).setData({
          type: "FeatureCollection",
          features: features as any,
        });
      }

      const wpLayer = map.current.getLayer(`waypoints-${m.missionID}`);
      // Add a layer to use the image to represent the data.
      if (!wpLayer) {
        console.debug(`map does not have layer. adding waypoints...`);
        map.current.addLayer({
          id: `waypoints-${m.missionID}`,
          type: "symbol",
          source: `waypoints-${m.missionID}`,
          layout: {
            "icon-image": "WP_Green",
            "icon-size": 0.5,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "text-field": ["get", "waypointNumber"],
            "text-allow-overlap": true,
            "text-ignore-placement": true,
            "symbol-sort-key": ["get", "waypointNumber"],
            "symbol-z-order": "source",
            visibility:
              activeMissionNames.includes(m.missionName) ||
              (activeMission && showPreview)
                ? "visible"
                : "none",
          },
          paint: {
            "text-color": "#333333",
            "icon-opacity": 0.8,
          },
        });
      } else {
        map.current.setLayoutProperty(
          wpLayer.id,
          "visibility",
          activeMissionNames.includes(m.missionName) ||
            (activeMission?.missionName === m.missionName && showPreview)
            ? "visible"
            : "none"
        );
      }

      map.current?.moveLayer(
        `route-${m.missionID}`,
        `waypoints-${m.missionID}`
      );

      return null;
    });

    if (actualPath) {
      const actualSource = map.current.getSource(`route-actual`);
      if (!actualSource) {
        map.current.addSource(`route-actual`, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: actualPath,
            },
          },
        });
      } else {
        (map.current.getSource(`route-actual`) as GeoJSONSource).setData({
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: actualPath
          },
        });
      }

      const actualRoute = map.current.getLayer(`route-actual`);

      if (!actualRoute) {
        map.current.addLayer({
          id: `route-actual`,
          type: "line",
          source: `route-actual`,
          layout: {
            "line-join": "round",
            "line-cap": "round",
            visibility: "visible",
          },
          paint: {
            "line-color": "#795695",
            "line-width": 6,
            "line-opacity": 0.5,
          },
        });
      }

      map.current?.moveLayer(
        "route-actual",
      );
    }
  }, [
    dataLoaded,
    hasStyleData,
    sleds,
    missions,
    activeSledGroup,
    activeSled,
    activeMission,
    showPreview,
    actualPath,
  ]);

  useEffect(() => {
    // console.log('174', { sleds, activeSledGroup, activeSled, dataLoaded, hasStyleData });
    if (
      !map.current ||
      !sleds ||
      !activeSledGroup ||
      !activeSled ||
      !dataLoaded ||
      !hasStyleData
    )
      return; // wait for map to initialize

    const activeSleds = sleds.filter(
      (s) => s.sledGroupID === activeSledGroup?.sledGroupID
    );
    const activeSledIDs = activeSleds.map((s) => s.sledID);

    // Load an image from an external URL.
    if (!map.current.hasImage("wheel-sled")) {
      map.current.loadImage(
        `${window.location.origin}/wheel-sled.png`,
        (error: any, image: any) => {
          if (error) console.error(error);
          if (!map.current) return;
          // Add the image to the map style.
          if (!map.current.hasImage("wheel-sled")) {
            map.current.addImage("wheel-sled", image);
          }
        }
      );
    }

    sleds.map((s, i) => {
      if (!map.current) return;
      const sledSource = map.current.getSource(`point-${s.sledID}`);
      // Add a data source containing one point feature.
      if (!sledSource) {
        // console.debug(`map does not have source. adding point-${s.sledID}...`);
        map.current.addSource(`point-${s.sledID}`, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [s.lastLongitude, s.lastLatitude],
                },
                properties: {},
              },
            ],
          },
        });
      } else {
        (map.current.getSource(`point-${s.sledID}`) as GeoJSONSource).setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [s.lastLongitude, s.lastLatitude],
              },
              properties: {},
            },
          ],
        });
      }

      const sledPoint = map.current.getLayer(`points-${s.sledID}`);
      // Add a layer to use the image to represent the data.
      if (!sledPoint) {
        map.current.addLayer({
          id: `points-${s.sledID}`,
          type: "symbol",
          source: `point-${s.sledID}`, // reference the data source
          layout: {
            "icon-image": "wheel-sled", // reference the image
            "icon-size": activeSled.sledID === s.sledID ? 0.75 : 0.5,
            "icon-rotate": s.lastHeading,
            visibility: activeSledIDs.includes(s.sledID) ? "visible" : "none",
          },
        });
      } else {
        if (activeSled.sledID === s.sledID) {
          map.current.setLayoutProperty(sledPoint.id, "icon-size", 0.75);
        } else {
          map.current.setLayoutProperty(sledPoint.id, "icon-size", 0.5);
        }

        map.current.setLayoutProperty(
          sledPoint.id,
          "icon-rotate",
          s.lastHeading
        );

        map.current.setLayoutProperty(
          sledPoint.id,
          "visibility",
          activeSledIDs.includes(s.sledID) ? "visible" : "none"
        );
      }
      return null;
    });
  }, [
    dataLoaded,
    hasStyleData,
    sleds,
    missions,
    activeSledGroup,
    activeSled,
    activeMission,
  ]);

  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{
        // height: "100vh",
        height: window.innerHeight - 57,
        width: "100%",
      }}
    >
      <div className="map-position">
        Longitude: {lng.toFixed(4)} | Latitude: {lat.toFixed(4)} | Zoom:{" "}
        {zoom.toFixed(2)}
      </div>
    </div>
  );
}

export default MissionControlMap;
