import React from "react";
import logo from "./logo.svg";
import "../App.scss";
import { Link, NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Organization } from "../C2Client";

function Navbar({
  organization
}: {
  organization: Organization | null;
}) {
  const { isAuthenticated, loginWithRedirect, logout, isLoading, user } =
    useAuth0();

  const handleLogin = async (e: any) => {
    e.preventDefault();

    await loginWithRedirect({
      appState: {
        returnTo: "/control",
        // returnTo: `/control?organizationID=${organization?.organizationID}`,
      },
    });
  };

  const handleLogout = (e: any) => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <nav
      className="navbar navbar-expand-lg bg-body-tertiary"
      data-bs-theme="dark"
    >
      {/* <nav className="navbar navbar-expand-lg bg-dark text-white" data-bs-theme="dark"> */}
      <div className="container-fluid">
        <Link to={`/control?organizationID=${organization?.organizationID}`} className="navbar-brand">
          <img src="/favicon@2x.png" alt="Logo" width="30" height="30" className="d-inline-block align-text-top me-2" />
          Sled Control Console
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink
                to={`/control?organizationID=${organization?.organizationID}`}
                className={({ isActive, isPending }) =>
                  isActive
                    ? "nav-link active"
                    : isPending
                    ? "nav-link pending"
                    : "nav-link"
                }
              >
                Mission Control
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/planner?organizationID=${organization?.organizationID}`}
                className={({ isActive, isPending }) =>
                  isActive
                    ? "nav-link active"
                    : isPending
                    ? "nav-link pending"
                    : "nav-link"
                }
              >
                Mission Planner
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/settings?organizationID=${organization?.organizationID}`}
                className={({ isActive, isPending }) =>
                  isActive
                    ? "nav-link active"
                    : isPending
                    ? "nav-link pending"
                    : "nav-link"
                }
              >
                Settings
              </NavLink>
            </li>
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Settings
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    Customer
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Sleds
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Configuration
                  </a>
                </li>
              </ul>
            </li> */}
          </ul>
          {isAuthenticated && user ? (
              <div>
                <img className="rounded me-2" src={user.picture} alt={user.name} style={{ width: '38px' }} />
                <button
                  className="btn btn-outline-success"
                  onClick={handleLogout}
                >
                  Log Out
                </button>
              </div>
            ) : (
              <button className="btn btn-outline-success" onClick={handleLogin}>
                Log In
              </button>
            )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
