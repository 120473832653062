import "../App.scss";
import SledGroupControl from "../SledGroupControl";
import { useEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import sledSvg from "../assets/sled.svg";
// import wheelSled from "../assets/wheel-sled.png";
import wheelSled from "../assets/wheel-sled.svg";
import {
  ControlParams,
  ControlData,
  Mission,
  Organization,
  Sled,
  SledCommand,
  SledGroup,
  SledGroupCommand,
  SledStatus,
} from "../C2Client";
import MissionControlMap from "../components/MissionControlMap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Navbar from "../components/Navbar";
import { Link, useLocation } from "react-router-dom";
import { Units, point, distance, Position } from "@turf/turf";
import DiagTerminal from "../components/DiagTerminal";
import moment from "moment";

// const batteryVoltageCutoff = 43.2;
const batteryVoltageCutoff = 32.4;
const desBatteryVoltageCutoff = 44.72;

function getBatteryCutoff(sledName: string) {
  return sledName === "Sled100" ? desBatteryVoltageCutoff : batteryVoltageCutoff;
}

function MissionControl() {
  const location = useLocation();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const [apiToken, setApiToken] = useState<string | null>(null);
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [sledGroups, setSledGroups] = useState<SledGroup[] | null>(null);
  const [sledGroup, setSledGroup] = useState<SledGroup | null>(null);
  const [sleds, setSleds] = useState<Sled[] | null>(null);
  const [filteredSleds, setFilteredSleds] = useState<Sled[] | null>(null);
  const [sled, setSled] = useState<Sled | null>(null);
  const [missions, setMissions] = useState<Mission[] | null>(null);
  const [sledMission, setSledMission] = useState<Mission | undefined>(
    undefined
  );
  const [controlParams, setControlParams] = useState<ControlParams | null>(
    null
  );
  const [controlData, setControlData] = useState<ControlData | null>(null);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [hasSocketConnection, setHasSocketConnection] =
    useState<boolean>(false);
  const [isChangingMission, setIsChangingMission] = useState<boolean>(false);
  const [tooFarToStart, setTooFarToStart] = useState<boolean>(false);
  const [actualPath, setActualPath] = useState<Position[]>([]);
  const [controls, setControls] = useState<any | null>({
    select: false,
    load: true,
    clear: false,
    start: false,
    pause: false,
    continue: false,
    stop: false,
    shutdown: true,
    diag: true,
  });

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;

      try {
        const apiAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:current_user offline_access",
          },
        });

        setApiToken(apiAccessToken || "");

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${domain}/api/v2/`,
            scope: "openid profile email offline_access read:current_user",
          },
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();

        setUserMetadata(user_metadata);
      } catch (e: any) {
        console.debug(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    if (!apiToken) return;

    const url = new URL(window.location.href);

    let organizationID = url.searchParams.get("organizationID");
    let sledGroupID = url.searchParams.get("sledGroupID");
    let sledID = url.searchParams.get("sledID");

    const fetchDefaultParams = async () => {
      const paramResponse = await fetch(
        `${process.env.REACT_APP_API}/defaults`,
        {
          headers: { Authorization: `Bearer ${apiToken}` },
        }
      );
      const params = (await paramResponse.json()) as ControlParams;

      console.log("DefaultParams: ", params);
      window.history.replaceState(
        null,
        "",
        `/control?organizationID=${params.organizationID}&sledGroupID=${params.sledGroupID}&sledID=${params.sledID}`
      );
      setControlParams(params);
    };

    if (organizationID && sledGroupID && sledID) {
      const params = { organizationID, sledGroupID, sledID } as ControlParams;
      console.log("SearchParams: ", params);
      setControlParams(params);
    } else {
      fetchDefaultParams();
    }
  }, [apiToken]);

  useEffect(() => {
    if (!controlParams) return;

    const fetchControlData = async (organizationID: string) => {
      const dataResponse = await fetch(
        `${process.env.REACT_APP_API}/control-data?OrganizationID=${organizationID}`
      );
      const data = await dataResponse.json();

      setOrganization(data.organization);
      setSledGroups(data.sledGroups);
      setSleds(data.sleds);
      setMissions(data.missions);
      setControlData(data);
      setDataLoaded(true);
    };

    fetchControlData(controlParams.organizationID);
  }, [controlParams?.organizationID]);

  useEffect(() => {
    // console.log('here 1', {controlParams, sledGroups, sleds})
    if (!controlParams || !sledGroups || !sleds) return;

    const groupData = sledGroups.find(
      (g: SledGroup) => g.sledGroupID === controlParams?.sledGroupID
    );
    let updatedSledList = [];
    if (groupData) {
      updatedSledList = sleds
        ?.filter((s: Sled) => s.sledGroupID === controlParams?.sledGroupID)
        .sort((s1, s2) =>
          s1.sledName > s2.sledName ? 1 : s2.sledName > s1.sledName ? -1 : 0
        );
    } else {
      return;
    }

    setSledGroup(groupData);
    setFilteredSleds(updatedSledList as Sled[]);
  }, [controlParams?.sledGroupID, sledGroups, sleds]);

  useEffect(() => {
    // console.log('here 2', {controlParams, filteredSleds, missions})
    if (!controlParams || !filteredSleds || !missions) return;

    const sledData = filteredSleds.find(
      (s: Sled) => s.sledID === controlParams?.sledID
    );
    if (sledData) {
      const missionData = missions.find(
        (m) => m.missionName === sledData.lastMission
      );
      const controlOptions = getControlOptions(sledData, missionData);

      setSled(sledData);
      setSledMission(missionData);
      setControls(controlOptions);
    }
  }, [controlParams?.sledID, filteredSleds, missions]);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API}/hubs/sled`)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection && !connection.connectionId) {
      connection
        .start()
        .then((result: any) => {
          console.debug("Started status connection!", result);
          setHasSocketConnection(true);
        })
        .catch((e) => console.debug("Websocket connection failed: ", e));
    }
    return () => {
      if (connection && connection.connectionId) {
        connection
          .stop()
          .then((result: any) => {
            console.debug("Stopped status connection!", result);
            setHasSocketConnection(false);
          })
          .catch((e) => console.debug("Websocket stop connection failed: ", e));
      }
    };
  }, [connection]);

  useEffect(() => {
    if (connection?.connectionId && sled?.sledID) {
      connection.on(
        "SledStatusMessage",
        (sledID: string, status: SledStatus) => {
          console.log("SledStatusMessage:", status);
          if (sleds && !isChangingMission && sled?.sledID === sledID) {
            const sledList = [...sleds.filter((s) => s.sledID !== sledID)];

            const updated = sleds.find((s) => s.sledID === sledID);

            if (updated) {
              updated.lastStatusMessage = status.statusMessage;
              updated.lastStatusDate = status.statusDate;
              updated.lastMission = status.mission;
              updated.lastLatitude = status.latitude;
              updated.lastLongitude = status.longitude;
              updated.lastHeading = status.heading;
              updated.lastSpeedMS = status.speedMS;
              updated.lastBatteryStatus = status.batteryStatus;
              updated.lastLatitudeAccuracy = status.latitudeAccuracy;
              updated.lastLongitudeAccuracy = status.longitudeAccuracy;
              updated.lastHeadingAccuracy = status.headingAccuracy;

              sledList.push(updated);

              setActualPath((prev) => [
                ...prev,
                [status.longitude, status.latitude],
              ]);
            }
            setSleds(sledList);
          }
        }
      );
    }

    return () => {
      if (!connection?.connectionId) return;
      connection.off("SledStatusMessage");
    };
  }, [hasSocketConnection, sled?.sledID, isChangingMission]);

  const handleSledGroupCommand = async (gid: string, message: string) => {
    if (!apiToken) return;

    const sledGroupCommand = {
      // commandID: randomUUID(),
      commandID: "",
      sledGroupID: gid,
      message,
    };

    const sendSledGroupCommand = async (command: SledGroupCommand) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/sled-groups/${command.sledGroupID}/command`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${apiToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(command),
          }
        );
        const result = await response.json();
      } catch (e) {
        console.debug(e);
      }
    };

    sendSledGroupCommand(sledGroupCommand);
  };

  const handleSledCommand = async (sid: string, message: string) => {
    if (!apiToken) return;

    const sledGroupCommand = {
      // commandID: randomUUID(),
      commandID: "",
      sledID: sid,
      message,
    };

    const sendSledCommand = async (command: SledCommand) => {
      // console.log('command', command)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/sleds/${command.sledID}/command`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${apiToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(command),
          }
        );
        const result = await response.json();
      } catch (e) {
        console.debug(e);
      }
    };

    sendSledCommand(sledGroupCommand);
  };

  const handleLoadMission = async (sid: string, m: Mission) => {
    if (!apiToken) return;

    const sendLoadMission = async (mission: Mission) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/sleds/${sid}/mission`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${apiToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(mission),
          }
        );
        const result = await response.json();
        if (response.ok) {
          setIsChangingMission(false);
        }
      } catch (e) {
        console.debug(e);
      }
    };

    sendLoadMission(m);
  };

  const changeMission = (mission: string) => {
    setIsChangingMission(true);
    const missionData = missions?.find((m) => m.missionName === mission);
    setSledMission(missionData);
  };

  const getMissionID = (mission: string) => {
    const mid = missions?.find(
      (m: Mission) => m.missionName === mission
    )?.missionID;
    return mid;
  };

  const changeSledGroup = (gid: string) => {
    setActualPath([]);
    if (!sledGroups) return;
    const groupData = sledGroups.find((g: SledGroup) => g.sledGroupID === gid);
    setSledGroup(groupData as SledGroup);

    const updatedSledList = sleds?.filter((s: Sled) => s.sledGroupID === gid);
    updatedSledList?.sort((s1, s2) =>
      s1.sledName > s2.sledName ? 1 : s2.sledName > s1.sledName ? -1 : 0
    );
    setFilteredSleds(updatedSledList as Sled[]);

    const sledData = updatedSledList ? updatedSledList[0] : null;
    if (sledData) {
      setSled(sledData);
      const missionData = missions?.find(
        (m) => m.missionName === sledData.lastMission
      );
      setSledMission(missionData);
      const controlOptions = getControlOptions(sledData, missionData);
      setControls(controlOptions);

      const organizationID = organization!.organizationID;
      const sledGroupID = gid;
      const sledID = sledData.sledID;
      setControlParams({ organizationID, sledGroupID, sledID });
      updateDefaultParams(organizationID, sledGroupID, sledID);
      window.history.pushState(
        null,
        "",
        `/control?organizationID=${organizationID}&sledGroupID=${sledGroupID}&sledID=${sledID}`
      );
    }
  };

  const updateDefaultParams = async (
    organizationID: string,
    sledGroupID: string,
    sledID: string
  ) => {
    const response = await fetch(`${process.env.REACT_APP_API}/defaults`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${apiToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ organizationID, sledGroupID, sledID }),
    });
    const params = (await response.json()) as ControlParams;
  };

  const changeSled = (sid: string) => {
    setActualPath([]);
    const organizationID = organization!.organizationID;
    const sledGroupID = sledGroup!.sledGroupID;
    const sledID = sid;
    setControlParams({ organizationID, sledGroupID, sledID });
    updateDefaultParams(organizationID, sledGroupID, sledID);
    window.history.pushState(
      null,
      "",
      `/control?organizationID=${organizationID}&sledGroupID=${sledGroupID}&sledID=${sledID}`
    );
    updateSledDataAndControlOptions(sledID);
  };

  const updateSledDataAndControlOptions = (sid: string) => {
    const sledData = sleds?.find((s: Sled) => s.sledID === sid);
    setSled(sledData as Sled);
    const missionData = missions?.find(
      (m: Mission) => m.missionName === sledData?.lastMission
    );
    setSledMission(missionData);
    const controlOptions = getControlOptions(sledData, missionData);
    setControls(controlOptions);
  };

  const getControlOptions = (
    sledData: Sled | undefined,
    missionData: Mission | undefined
  ) => {
    let controlOptions = {
      select: true,
      load: true,
      clear: false,
      start: false,
      pause: false,
      continue: false,
      stop: false,
      shutdown: true,
      diag: true,
    };

    var lastStatus = sledData?.lastStatusMessage;
    var beginWaypoint = missionData?.waypoints[0];

    if (sledData && lastStatus && missionData && beginWaypoint) {
      switch (lastStatus) {
        case "No Mission":
          controlOptions.load = sledMission?.missionName == sled?.lastMission;
          break;
        case "Has Mission, Not Started":
          var from = point([sledData.lastLongitude, sledData.lastLatitude]);
          var to = point([beginWaypoint.longitude, beginWaypoint.latitude]);
          var options = { units: "kilometers" as Units };

          var dKilometers = distance(from, to, options);
          var dMeters = dKilometers * 1000;

          // console.log(
          //   "distance: ",
          //   dKilometers,
          //   dMeters,
          //   missionData?.startRadiusMeters,
          //   dMeters < missionData?.startRadiusMeters
          // );

          if (dMeters > missionData?.startRadiusMeters) {
            setTooFarToStart(true);
          }

          controlOptions = {
            select: false,
            load: false,
            clear: true,
            start: true,
            pause: false,
            continue: false,
            stop: false,
            shutdown: true,
            diag: true,
          };
          break;
        case "Mission Ended":
          var from = point([sledData.lastLongitude, sledData.lastLatitude]);
          var to = point([beginWaypoint.longitude, beginWaypoint.latitude]);
          var options = { units: "kilometers" as Units };

          var dKilometers = distance(from, to, options);
          var dMeters = dKilometers * 1000;

          // console.log(
          //   "distance: ",
          //   dKilometers,
          //   dMeters,
          //   missionData?.startRadiusMeters,
          //   dMeters < missionData?.startRadiusMeters
          // );

          if (dMeters > missionData?.startRadiusMeters) {
            setTooFarToStart(true);
          }

          controlOptions = {
            select: false,
            load: false,
            clear: true,
            start: true,
            pause: false,
            continue: false,
            stop: false,
            shutdown: true,
            diag: true,
          };
          break;
        case "Paused":
          controlOptions = {
            select: false,
            load: false,
            clear: false,
            start: false,
            pause: false,
            continue: true,
            stop: true,
            shutdown: true,
            diag: true,
          };
          break;
        case "Running":
          controlOptions = {
            select: false,
            load: false,
            clear: false,
            start: false,
            pause: true,
            continue: false,
            stop: true,
            shutdown: false,
            diag: true,
          };
          break;
        case "Waiting":
          controlOptions = {
            select: false,
            load: false,
            clear: false,
            start: false,
            pause: false,
            continue: true,
            stop: true,
            shutdown: false,
            diag: true,
          };
          break;
        case "Powered Off":
          controlOptions = {
            select: false,
            load: false,
            clear: false,
            start: false,
            pause: false,
            continue: false,
            stop: false,
            shutdown: false,
            diag: false,
          };
          break;
        default:
          break;
      }
    }

    return controlOptions;
  };

  return (
    <>
      <Navbar organization={organization} />
      {!dataLoaded ? (
        <div className="vh-100 vw-100 d-flex justify-content-center">
          <img
            src={wheelSled}
            className="align-self-end"
            style={{
              animation: "myAnim 2s ease 0s 1 normal forwards",
              height: "100px",
              width: "auto",
            }}
          />
        </div>
      ) : (
        <div className="">
          <div className="row g-0">
            <div className="col">
              <div className="row mx-0 my-2">
                <div className="col-12">
                  <SledGroupControl
                    sledGroups={sledGroups as SledGroup[]}
                    selectedSledGroup={sledGroup as SledGroup}
                    onChange={changeSledGroup}
                    onClick={handleSledGroupCommand}
                  />
                  <hr />
                  {filteredSleds &&
                    filteredSleds.map((s: Sled) => (
                      <div
                        key={s.sledID}
                        className={
                          s.sledID === sled?.sledID
                            ? "card my-3 bg-success-subtle"
                            : "card my-3"
                        }
                        style={{ width: "18rem" }}
                        onClick={() => changeSled(s.sledID)}
                      >
                        <div className="card-body">
                          <h5 className="card-title">{s.sledName}</h5>
                          <h6 className="card-subtitle mb-2 text-body-secondary">
                            {s.lastMission !== "No Mission" ? (<Link
                            to={`/planner?organizationID=${
                              organization?.organizationID
                            }&missionID=${getMissionID(s.lastMission)}`}
                          >
                            {s.lastMission}
                          </Link>) : ("No Mission")}
                          </h6>
                          <p className="m-0">{s.lastStatusMessage}</p>
                          <small>{moment(s.lastStatusDate).fromNow()}</small>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-8">
              <MissionControlMap
                dataLoaded={dataLoaded}
                sleds={sleds as Sled[]}
                missions={missions as Mission[]}
                activeSledGroup={sledGroup as SledGroup}
                activeSled={sled as Sled}
                activeMission={sledMission as Mission}
                showPreview={isChangingMission}
                actualPath={actualPath}
                cParams={controlParams}
              />
            </div>
            <div className="col">
              <div className="row mx-0 my-2">
                <div className="col-12">
                  {sled && (
                    <div className="">
                      <h3 className="text-center my-3">{sled.sledName}</h3>
                      <img
                        src={wheelSled}
                        className="w-50 mx-auto d-block"
                        alt="Sled Image"
                      />
                      <hr />
                      <dl className="row">
                        <dt className="col-4">Mission</dt>
                        <dd className="col-8">{sled.lastMission}</dd>

                        <dt className="col-4">Status</dt>
                        <dd className="col-8">{sled.lastStatusMessage}</dd>

                        <dt className="col-4">Timestamp</dt>
                        <dd className="col-8">
                          {new Date(sled?.lastStatusDate).toLocaleString()}
                        </dd>

                        <dt className="col-4">Speed</dt>
                        <dd className="col-8">
                          {sled.lastSpeedMS.toFixed(1)} m/s
                        </dd>

                        <dt className="col-4">Heading</dt>
                        <dd className="col-8">
                          {Math.floor(sled.lastHeading)}&deg;{" "}
                          <small>
                            ({sled.lastHeadingAccuracy.toFixed(2)}&deg;)
                          </small>
                        </dd>

                        {/* <dt className="col-4">Heading Accuracy</dt>
                        <dd className="col-8">{sled.lastHeadingAccuracy.toFixed(2)}&deg;</dd> */}

                        <dt className="col-4">Latitude</dt>
                        <dd className="col-8">
                          {sled.lastLatitude.toFixed(11)} <small>({sled.lastLatitudeAccuracy.toFixed(2)}m)</small>
                        </dd>

                        <dt className="col-4">Longitude</dt>
                        <dd className="col-8">
                          {sled.lastLongitude.toFixed(11)} <small>({sled.lastLongitudeAccuracy.toFixed(2)}m)</small>
                        </dd>

                        {/* <dt className="col-4">Latitude Accuracy</dt>
                        <dd className="col-8">
                          {sled.lastLatitudeAccuracy.toFixed(2)}m
                        </dd>

                        <dt className="col-4">Longitude Accuracy</dt>
                        <dd className="col-8">
                          {sled.lastLongitudeAccuracy.toFixed(2)}m
                        </dd> */}

                        <dt className="col-4">Battery</dt>
                        {/* <dd className="col-8">{sled.lastBatteryStatus}V <small>({(sled.lastBatteryStatus - getBatteryCutoff(sled.sledName)) > 0 ? `${(sled.lastBatteryStatus - getBatteryCutoff(sled.sledName)).toFixed(2)}V` : `${(sled.lastBatteryStatus - getBatteryCutoff(sled.sledName)).toFixed(2)}V`})</small></dd> */}
                        <dd className="col-8">{sled.lastBatteryStatus}V <small>({sled.lastBatteryStatus - getBatteryCutoff(sled.sledName) > 0 ? ((sled.lastBatteryStatus - getBatteryCutoff(sled.sledName)) / (50.3 - getBatteryCutoff(sled.sledName)) * 100).toFixed(0) : 0}%)</small></dd>
                      </dl>
                      <hr />
                      <div className="mb-3">
                        <select
                          className="form-select"
                          aria-label="Sled Mission Select"
                          value={sledMission?.missionName}
                          onChange={(e) => changeMission(e.target.value)}
                          placeholder="Select a Mission"
                          disabled={!controls.select}
                        >
                          {/* <option>Select a Mission</option> */}
                          {missions &&
                            missions.map((m: Mission) => (
                              <option key={m.missionID} value={m.missionName}>
                                {m.missionName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="d-flex justify-content-start mb-2">
                        <button
                          className="btn btn-success w-50"
                          onClick={() =>
                            handleLoadMission(sled.sledID, sledMission!)
                          }
                          disabled={!controls.load}
                        >
                          Load
                        </button>
                        <button
                          className="btn btn-danger w-50 ms-2"
                          onClick={() =>
                            handleSledCommand(sled.sledID, "Clear Mission")
                          }
                          disabled={!controls.clear}
                        >
                          Clear
                        </button>
                      </div>
                      <div className="d-flex justify-content-start mb-2">
                        <button
                          className={`btn ${
                            tooFarToStart ? "btn-warning" : "btn-success"
                          } w-50`}
                          onClick={() => {
                            setActualPath([]);
                            if (tooFarToStart) {
                              if (
                                window.confirm(
                                  "Too far to start. Do you want to start the mission anyway?"
                                )
                              ) {
                                handleSledCommand(sled.sledID, "Start Mission");
                              }
                            } else {
                              handleSledCommand(sled.sledID, "Start Mission");
                            }
                          }}
                          disabled={!controls.start}
                        >
                          Start Mission
                        </button>
                        <button
                          className="btn btn-danger w-50 ms-2"
                          onClick={() =>
                            handleSledCommand(sled.sledID, "Stop Mission")
                          }
                          disabled={!controls.stop}
                        >
                          Stop Mission
                        </button>
                      </div>
                      <div className="d-flex justify-content-start mb-2">
                        <button
                          className="btn btn-success w-50"
                          onClick={() =>
                            handleSledCommand(sled.sledID, "Continue Mission")
                          }
                          disabled={!controls.continue}
                        >
                          Continue
                        </button>
                        <button
                          className="btn btn-warning w-50 ms-2"
                          onClick={() =>
                            handleSledCommand(sled.sledID, "Pause Mission")
                          }
                          disabled={!controls.pause}
                        >
                          Pause
                        </button>
                      </div>

                      <div className="d-flex justify-content-start mb-2">
                        <button
                          className="btn btn-success w-50"
                          onClick={() =>
                            handleSledCommand(sled.sledID, "Enable Diag")
                          }
                          disabled={!controls.diag}
                        >
                          Enable Diag
                        </button>
                        <button
                          className="btn btn-warning w-50 ms-2"
                          onClick={() =>
                            handleSledCommand(sled.sledID, "Disable Diag")
                          }
                          disabled={!controls.diag}
                        >
                          Disable Diag
                        </button>
                      </div>

                      <div className="d-flex justify-content-start mb-2">
                        <button
                          className="btn btn-danger w-100"
                          onClick={() =>
                            handleSledCommand(sled.sledID, "Shut Down")
                          }
                          disabled={!controls.shutdown}
                        >
                          Shut Down
                        </button>
                      </div>
                      <hr />
                      <div className="mb-2" style={{ height: "225px" }}>
                        <DiagTerminal activeSledID={sled?.sledID} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withAuthenticationRequired(MissionControl);
