import moment from "moment";
import { useEffect, useState } from "react";
import {AutoSizer, Index, List} from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { SledDiagnosticMessage } from "../C2Client";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

function DiagTerminal({activeSledID}:{activeSledID:string}) {
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const [hasSocketConnection, setHasSocketConnection] = useState<boolean>(false);
    const [diagMessages, setDiagMessages] = useState<SledDiagnosticMessage[]>([]);

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_API}/hubs/sled`)
          .withAutomaticReconnect()
          .build();
    
        setConnection(newConnection);
      }, []);
    
      useEffect(() => {
        if (connection && !connection.connectionId) {
          connection
            .start()
            .then((result: any) => {
              console.debug("Started diag connection!", result);
              setHasSocketConnection(true);
            })
            .catch((e:any) => console.debug("Websocket connection failed: ", e));
        }
        return () => {
          if (connection && connection.connectionId) {
            connection
              .stop()
              .then((result: any) => {
                console.debug("Stopped diag connection!", result);
                setHasSocketConnection(false);
              })
              .catch((e:any) => console.debug("Websocket stop connection failed: ", e));
          }
        };
      }, [connection]);
    
      useEffect(() => {
        setDiagMessages([]);
        if (connection?.connectionId && activeSledID) {
          connection.on("SledDiagnosticMessage", (sledID: string, message: SledDiagnosticMessage) => {
            // console.log('SledDiagnosticMessage:', message);
            if (activeSledID === sledID) {
              if (message.message.includes('[DEBUG]')) {
                message.message = message.message.replace('[DEBUG]', '');
                message.level = 'DEBUG';
                message.className = 'text-bg-secondary';
              }
              if (message.message.includes('[ERROR]')) {
                message.message = message.message.replace('[ERROR]', '');
                message.level = 'ERROR';
                message.className = 'text-bg-danger';
              }
              if (message.message.includes('[WARNING]')) {
                message.message = message.message.replace('[WARNING]', '');
                message.level = 'WARNING';
                message.className = 'text-bg-warning';
              }
              setDiagMessages(prev => [message, ...(prev.length < 100 ? prev : prev.slice(0, -1))]);
            }
          });
        }
    
        return () => {
          if (!connection?.connectionId) return;
          connection.off('SledDiagnosticMessage');
        };
      }, [hasSocketConnection, activeSledID]);

  return (
    <>
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            rowCount={diagMessages.length}
            rowHeight={(i: Index) => {
                return diagMessages[i.index].message.length / 25 * 30;
            }}
            rowRenderer={({ index, key, style }) => (
              <p key={key} style={style} className={`small text-lowercase px-2 ${diagMessages[index].className}`}>{`${moment(
                diagMessages[index].clientCreationDate
              ).format("h:mm:ss")}: ${diagMessages[index].message}`}</p>
            )}
            width={width}
          />
        )}
      </AutoSizer>
    </>
  );
}

export default DiagTerminal;
